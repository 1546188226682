export const goto_type = [
    {
        value:'0',
        label:'全部'
    },{
        value:'1',
        label:'商家首页'
    },{
        value:'2',
        label:'商品详情'
    },{
        value:'3',
        label:'服务详情'
    }
]
